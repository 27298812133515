<template>
  <a-modal
    title="新建用户"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-form-item label="用户名" :labelCol="labelCol" :wrapperCol="wrapperCol" extra="用户名只能由字母、数字和下划线组成,且必须以字母开头、最低六位字符长度">
          <a-input
            v-decorator="['username',
                          {rules: [
                            {required: true, message: '请输入'},
                            {min:6, max:50, message: '输入不符合长度限制'}
                          ]}]" />
        </a-form-item>
        <a-form-item label="昵称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['nickname', {rules: [{required: true, message: '请输入'}]}]" />
        </a-form-item>
        <a-form-item label="密码" :labelCol="labelCol" :wrapperCol="wrapperCol" extra="密码最低设置八位字符长度">
          <a-input-password v-decorator="['password', {rules: [{required: true, message: '请输入'}, {min:8, message: '输入不符合长度限制'}]}]" />
        </a-form-item>
        <a-form-item label="账号分类" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
            @change="handleRoleChange"
            v-decorator="[
              'role',
              {
                rules: [
                  { required: true, message: '请选择' },
                ],
              },
            ]"
            placeholder="选择"
          >
            <a-select-option :value="1">
              管理员
            </a-select-option>
            <a-select-option :value="2">
              供应商
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="supplier_id" v-show="false">
          <a-input v-decorator="['supplier_id']" />
        </a-form-item>
        <a-form-item label="关联企业" :labelCol="labelCol" :wrapperCol="wrapperCol" v-show="is_show">
          <a-auto-complete
            v-decorator="['corporation', {rules: [{required: false, message: '必填项，请填写信息'}]}]"
            :data-source="dataSource"
            placeholder="请输入"
            @select="onSelect"
            @search="onSearch"
            @change="onChange"
          />
          <div class="ant-form-explain" v-if="!!tip">  {{ tip }} </div>
        </a-form-item>
        <a-form-item label="权限" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
            @change="handlePermissionChange"
            v-decorator="[
              'permission_group_id',
              {
                rules: [
                  { required: false, message: '请选择' },
                ],
              },
            ]"
            placeholder="选择"
          >
            <a-select-option v-for="item in allPerlist" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="是否活跃" :labelCol="labelCol" :wrapperCol="wrapperCol" v-show="false">
          <a-switch v-decorator="['is_active', {initialValue: true, valuePropName: 'checked'}]" />
        </a-form-item>
        <a-form-item label="邮箱" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['email', {rules: [{required: false, message: '请输入'}]}]" />
        </a-form-item>
        <a-form-item label="手机号" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['cellphone', {rules: [{required: false, message: '请输入'}]}]" />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { supplier_list } from '@/api/supplier'
import { permission_group_list } from '@/api/permission_group'
// 表单字段
const fields = ['id', 'create_time', 'last_update', 'username', 'password', 'nickname',
  'avatar', 'role', 'email', 'cellphone', 'location', 'is_active']
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      is_show: false,
      dataSource: [],
      tip: '',
      corList: [],
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      allPerlist: [],
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    handleRoleChange (value) {
      console.log(`selected ${value}`)
      if (value === 2) {
        this.is_show = true
      } else {
        this.is_show = false
      }
      permission_group_list({ site: value === 2 ? 2 : 1 }).then((res) => {
        console.log(res, '权限组织')
        this.allPerlist = res.data
      })
    },
    handlePermissionChange (value) {
      console.log(`selected ${value}`)
    },
    onChange (value) {
      console.log(value)
    },
    onSearch (searchText) {
      supplier_list({ name: searchText }).then((res) => {
        const reslut = res.data.entries || []
        this.corList = reslut
        if (reslut.length === 0) {
          this.tip = '未找到您输入的企业'
        } else {
          this.tip = ''
        }
        console.log('我是企业列表', reslut)
        this.dataSource = !searchText ? [] : reslut.map((item) => item.name)
      })
    },
    onSelect (value) {
      console.log(value, '我是选择的值', this.corList)
      // eslint-disable-next-line eqeqeq
      const supplier_id = this.corList.filter((item) => (item.name === value) && item)[0].id
      this.form.setFieldsValue({
        supplier_id: supplier_id
      })
      console.log('onSelect', value, this.corList.filter((item) => (item.name === value) && item))
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    }
  }
}
</script>
